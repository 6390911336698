<template>
  <!-- 实时数据 -->
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-row  class="">
          <v-col cols="12" sm="6" md="4">
            <v-select :label="$t('lang.station.stationName')" solo :items="roverStationList" return-object item-value="sid" item-text="name" v-model="selectedRoverStation"></v-select>
            
          </v-col>
          <v-col cols="12" sm="2" md="2" class="mt-2">
            <v-btn color="primary" @click="submit()">{{$t('lang.setting.submitBtn')}}</v-btn>
          </v-col>
          
        </v-row>
        <!-- <v-row no-gutters>
          <v-col cols="12">
            <span class="note">注：数据按照由近及远的顺序依次显示为 </span>
            <div class="circle" style="background-color: red;"></div>
            <div class="circle" style="background-color: orange;"></div>
            <div class="circle" style="background-color: green;"></div>
            <div class="circle" style="background-color: blue;"></div>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <div
              id="line-chart"
              :class="$vuetify.breakpoint.smAndDown ? 'min-chart-size' : 'max-chart-size'"
            ></div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
//import * as d3 from "d3-random";
//import * as d3Array from "d3-array";
import Plotly from "plotly.js-dist-min";
import axios from "axios";
import commonCfg from "../../config/common";
let apiUrl = commonCfg.commonUrl;
let wsUrl = commonCfg.websocketUrl

var stompjs = require('../../assets/js/stomp.js')
var Stomp = stompjs.Stomp
var client;
// plotly配置参数
let plotConfig = {
  displaylogo: false,
  responsive: true,
  //modeBarButtonsToRemove: ["select2d", "toggleSpikelines", "lasso2d"],
  //locale: "zh-CN",
};
export default {
  data() {
    return {
      roverStationList: [],
      selectedRoverStation: '',
      userList: []
    }; 
  },
  created() {
    this.getStationList()
    if(this.$root.authority == 'ROLE_ADMIN' || this.$root.authority == 'ROLE_USER_ADMIN') this.getUserList()
  },
  mounted() {
    this.initLineChart();
  },
  beforeDestroy() {
    if(client && client.connected) this.disconnect()
  },
  methods: {
    getStationList() {
      axios.get(`${apiUrl}station/list`).then(res => {
        if(res.status == 200) {
          this.roverStationList = res.data.filter(station => station.type == 'ROVER')  
          //console.log(this.roverStationList)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    //获得用户列表
    getUserList() {
      axios
        .get(`${apiUrl}admin/user/list`)
        .then((res) => {
          console.log(res.data);
          if (res.status == 200) {
            this.userList = res.data
          }
        })
        .catch((error) => {
          console.log(error);
          this.userList = [];
        });
    },
    getUserToken() {
      if(this.$root.authority == 'ROLE_USER') {// 普通用户
        return this.$root.userToken
      }
      //管理员
      let userId = this.selectedRoverStation.userId
      let user = this.userList.filter(user => user.id == userId)[0]
      return user.token
    },
    submit() {
      if(!this.selectedRoverStation) return
      if(client && client.connected) {
        this.disconnect()
        this.initLineChart()
      }
      this.connectSrv(this.getUserToken())
    },
    connectSrv(token){ 
      // 创建 STOMP 客户端后
      client = Stomp.client(wsUrl + token)
      // client.debug = function(str) {
      //   console.log(str)
      // };
      // 失败自动重连 Add the following if you need automatic reconnect (delay is in milli seconds)
      client.reconnect_delay = 5000;
      // 连接到 STOMP 服务器并进行身份验证
      client.connect('', '', this.connectCallback, this.errorCallback, this.closeEventCallback)

    },
    // 连接成功
    connectCallback(frame) {
      console.log('connection success:')
      console.log(frame)
      //console.log(client)
      this.subscribe()
    },
    // 订阅消息
    subscribe() {
      client.subscribe('/topic/gnss/' + this.selectedRoverStation.sid, this.showMessage);
    },
    // 连接失败
    errorCallback(frame) {
      console.log('connection error:')
      console.log(frame)
      console.log(client)
    },
    closeEventCallback(evt) {
      console.log(evt)
    },
    // message callback
    showMessage(msg) {
      console.log(msg)
      let msgObj = JSON.parse(msg.body)
      let time = new Date(msgObj.time)
      Plotly.extendTraces(
          'line-chart',
          {
            x: [[time], [time], [time]],
            y: [[msgObj.x], [msgObj.y], [msgObj.z]],
          },
          [0, 1, 2],
          30
        );
       
    },
    // unsubscribe() {
    //   console.log('stop receiving messages');
    //   subscription.unsubscribe();
    // },
    // 断开连接
    disconnect() {
      client.disconnect(() => {
        console.log('Disconnect the client from the server')
      })
    },
    // createPlotlyChart() {
    //   var x0 = normal_array(2, 0.45, 300);
    //   var y0 = normal_array(2, 0.45, 300);
    //   var x1 = normal_array(6, 0.4, 200);
    //   var y1 = normal_array(6, 0.4, 200);
    //   var x2 = normal_array(4, 0.3, 200);
    //   var y2 = normal_array(4, 0.3, 200);
    //   //console.log(x0);
    //   //console.log(d3Array.min(x0))
    //   var data = [
    //     {
    //       x: x0,
    //       y: y0,
    //       mode: "markers",
    //     },
    //     {
    //       x: x1,
    //       y: y1,
    //       mode: "markers",
    //     },
    //     {
    //       x: x2,
    //       y: y2,
    //       mode: "markers",
    //     },
    //     {
    //       x: x1,
    //       y: y0,
    //       mode: "markers",
    //     },
    //   ];

    //   var layout = {
    //     shapes: [
    //       {
    //         type: "circle",
    //         xref: "x",
    //         yref: "y",
    //         x0: d3Array.min(x0),
    //         y0: d3Array.min(y0),
    //         x1: d3Array.max(x0),
    //         y1: d3Array.max(y0),
    //         opacity: 0.2,
    //         fillcolor: "blue",
    //         line: {
    //           color: "blue",
    //         },
    //       },
    //       {
    //         type: "circle",
    //         xref: "x",
    //         yref: "y",
    //         x0: d3Array.min(x1),
    //         y0: d3Array.min(y1),
    //         x1: d3Array.max(x1),
    //         y1: d3Array.max(y1),
    //         opacity: 0.2,
    //         fillcolor: "orange",
    //         line: {
    //           color: "orange",
    //         },
    //       },
    //       {
    //         type: "circle",
    //         xref: "x",
    //         yref: "y",
    //         x0: d3Array.min(x2),
    //         y0: d3Array.min(y2),
    //         x1: d3Array.max(x2),
    //         y1: d3Array.max(y2),
    //         opacity: 0.2,
    //         fillcolor: "green",
    //         line: {
    //           color: "green",
    //         },
    //       },
    //       {
    //         type: "circle",
    //         xref: "x",
    //         yref: "y",
    //         x0: d3Array.min(x1),
    //         y0: d3Array.min(y0),
    //         x1: d3Array.max(x1),
    //         y1: d3Array.max(y0),
    //         opacity: 0.2,
    //         fillcolor: "red",
    //         line: {
    //           color: "red",
    //         },
    //       },
    //     ],
    //     //height: 400,
    //     //width: 480,
    //     showlegend: false
    //   };

    //   Plotly.newPlot("data", data, layout);
    // },

    // 折线数据配置
    lineChartData (name1, name2, name3) {
      let data1 = {
        name: name1,
        x: [],
        y: [],
        type: "scattergl",
        mode: "lines+markers",
      }
      let data2 = {
        name: name2,
        x: [],
        y: [],
        xaxis: 'x2',
        yaxis: 'y2',
        type: "scattergl",
        mode: "lines+markers",
      }
      let data3 = {
        name: name3,
        x: [],
        y: [],
        xaxis: 'x3',
        yaxis: 'y3',
        type: "scattergl",
        mode: "lines+markers",
      }
      return [data1, data2, data3]
    },
    // 折线图布局配置
    lineChartLayout (name, unit) {
      let layout = {
        title: name,
        yaxis: {
          title: {
            text: unit
          },
          //fixedrange: true
        },
      }
      return layout
    },
    initLineChart() {
      //折线图渲染
      Plotly.newPlot(
        "line-chart",
        this.lineChartData('X', 'Y', 'Z'),
        this.lineChartLayout('X/Y/Z',''),
        plotConfig
      );
    }
  },
};
// function normal_array(mean, stddev, size) {
//   var arr = new Array(size),
//     i;
//   var generator = (function () {
//     //返回用于生成具有正态（高斯）分布的随机数的函数
//     return d3.randomNormal(mean, stddev);
//   })();
//   for (i = 0; i < arr.length; i++) {
//     arr[i] = generator();
//   }
//   return arr;
// }
</script>
<style scoped>
.min-chart-size {
  max-width: 300px;
  max-height: 300px;
}
.max-chart-size {
  max-width: 500px;
  max-height: 500px;
}
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}
.note {
  font-size: 14px;
  font-weight: 550;
}
</style>